.headerFilter_header{
  padding: 1rem 2rem 0;
  color: #525252;
}

.headerFilter_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.headerFilter_filtersContainer{
  display: flex;
  align-items: center;
  flex: 0.8;
}

.headerFilter_filter{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 0.45;
}

.headerFilter_filter .MuiFormControl-root{
  width: 58%;
}
.headerFilter_datePicker .MuiInputBase-root{
  height: 40px;
  color: #0071BC;
}
.headerFilter_doctorSelect{
  width: 55%;
  height: 40px;
  color: #0071BC;
}
.headerFilter_buttonGroup{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}