.scheduleAppnt_inputRow {
  display: flex;
  gap: 16px; 
  align-items: center; 
}

.scheduleAppnt_inputContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.scheduleAppnt_datePicker {
  width: 100%; 
  margin-top: 10px !important;
  padding: 8.5px 14px !important; 
}
.scheduleAppnt_datePicker .MuiInputBase-input{
  padding: 8.5px 8px !important; 
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: -8px; 
}

.scheduleAppnt_ConsultaionFee{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem
}
.scheduleAppnt_ConsultaionFee p{
  color: #525252;
}