.bookAppointment_textContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}

.bookAppointment_textLabels{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.bookAppointment_textLabels p:first-child{
  color: #525252;
}
.bookAppointment_textLabels p:nth-child(2){
  color: #119645;
  font-weight: 600;
}