.filter_upperContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2.5rem;
  gap: 4rem;
  margin-top: 1rem;
}
.filter_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.filter_container p{
  color: #525252;
  font-size: 16px;
}
.select__control{
  width: 200px;
  font-size: 13px;
  
}
.filter_container .MuiFormHelperText-root{
  font-size: 10px;
}
.filter_container .MuiInputBase-input-MuiOutlinedInput-input{
  font-size:14px;
}

.filter_totalAmountSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #525252;
  font-size: 16px;
}