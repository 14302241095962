.dividerLine{
  border: 1px solid #E3E3E3;
  width: 95%;
  margin: 1rem auto 0.5rem;
}
.reporting_header{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2rem 0;
  color: #525252;
}
.reporting_header p{
  font-size: 24px;
  font-weight: bold;
  margin-left: 5px;
}