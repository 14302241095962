.patientInfo_infoContainer{
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.patientInfo_container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.patientInfo_key{
  color: #525252;
}

.patientInfo_value{
  color: #000;
}