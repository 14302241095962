.invoiceForm_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.invoiceForm_patientIds {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 600;
}

.invoiceForm_patientDetails {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  gap: 5px;
}

.invoiceForm_patientDetails p {
  color: #0071BC;
  font-size: 20px;
}

.invoiceForm_patientDetails .MuiFormControl-root {
  margin-top: 0px;
}

.invoiceForm_feeBreakUpContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.invoiceForm_feeBreakUp_title {
  font-size: 20px;
  color: #4A4A4A;
}

.invoiceForm_feeBreakUp {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invoiceForm_feeBreakUp div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.invoiceForm_feeBreakUp div p:first-child {
  color: #525252;
}

.invoiceForm_feeAmount {
  color: #119645;
  font-weight: 600;
}

.invoiceForm_feeBreakUp .MuiFormControl-root {
  margin-top: 0px;
}

.invoiceForm_procedure {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}


.invoiceForm_procedure .MuiFormControl-root {
  flex: 0.4;
  margin-top: 0;
}

.invoiceForm_paymentSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.invoiceForm_paymentModeSection {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  width: 25%;
}
.invoiceForm_paymentSelect{
  width: 180px;
}
.invoiceForm_modeSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.netPayableAmount_Container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  flex: 0.6;
}