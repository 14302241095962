.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
    text-align: center;
    color: #888;
  }
  
  .no-data-icon {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  
  .no-data-message {
    font-size: 1.5rem;
    font-weight: 600;
  }