.timeSlotsContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  padding: 10px 10px 0px 15px;
  max-height: 280px;
  overflow-y: auto;
}

.timeSlot {
  padding: 10px;
  background-color: #F1F1F1;
  border-radius: 4px;
  font-size: 14px;
  width: 175px;
  color: #4A4A4A;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.timeSlot.selected {
  background-color: #0071BC;
  color: #F1F1F1;
}
