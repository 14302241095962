.headerBar_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.headerBar_container div{
  display: flex; 
  align-items: center;
  gap: 0.5rem;
  color: #fff;
}