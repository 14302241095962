.cards_CardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.cards_container .MuiCardContent-root {
  padding: 0.5rem 1rem;
}

.cards_title {
  font-size: 18px;
  color: #000000 !important;
}

.cards_CardContent p:first-child {
  font-size: 16px;
  color: #525252;
}

.cards_CardContent p:nth-child(2) {
  font-size: 16px;
  color: #000000;
}

.cards_patientInfo {
  justify-content: left;
  gap: 0.5rem;
  padding-top: 2rem !important;
}

.cards_actionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards_actionButtons button {
  height: 30px;
  border-radius: 70px;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 12px;
}

.cards_arrivalButton {
  display: flex;
  gap: 6px;
}

.cards_arrived {
  color: #fff !important; 
  background-color: #36BD85!important;
  border: 1px solid #36BD85 !important;
}

.cards_notArrived {
  color: #fff!important;
  background-color: #FF7469!important;
  border: 1px solid #FF7469 !important;
}

.cards_doctorNotAvailable {
  color: #fff!important;
  background-color: #BF90E8!important;
  border: 1px solid #BF90E8 !important;

}

.cards_generateButton:disabled {
  background-color: #BDBDBD !important;
  color: #fff !important;
}

.card_appointmentType {
  padding: 4px 10px 2px 10px;
  border-radius: 0px 10px 0px 10px;
  background-color: #E6F5FF;
  color: #0071BC;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  text-align: center;
}

.cards_cardInvoiceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.cards_cardInvoiceContainer a{
  font-size: 14px;
  color: #0071BC;
  text-decoration: underline;
  font-weight: 600;
}

.cards_cardInvoiceContainer .divideLine{
  height: 15px;
  border: 1px solid #0071BC;
}
.card_cancelled{
  background-color: #E93729 !important;
  color: #fff !important;
}