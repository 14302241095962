.reportingTable .MuiTableCell-head , .reportingTable .MuiTableCell-body{
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}
.reportingTable_prescriptionUrl{
  font-size: 14px;
  color: #0071BC;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}